import { useUserStore } from '@/stores/user';
import { useCustomerStore } from '~/stores/customer';

export default defineNuxtRouteMiddleware((to) => {
  const nuxtApp = useNuxtApp();

  const user = useUserStore()
  const customer = useCustomerStore()

  if (!nuxtApp.$auth.loggedIn) {
    window.sessionStorage.setItem('redirectUrl', to.fullPath)
    return navigateTo('/login')
  }

  if (!user.user_id) {
    user.getUser()
    user.getNotifications()
  }

  if (customer.users.length < 1) {
    customer.getUsers()
  }

  if (customer.access.length < 1) {
    customer.getCustomerAccess()
  }
});